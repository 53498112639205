import React from "react"

import { withCollectionHeader } from "./withCollectionHeader"
import { Breadcrumbs } from "../../Breadcrumbs/Breadcrumbs"
import { HeroItem } from "../../Sections/Hero/Item/HeroItem"

export const CollectionHeader = withCollectionHeader(
  ({ banner, breadcrumbs, collection }): JSX.Element => (
    <>
      <HeroItem item={banner} size={`small`} />
      <Breadcrumbs colour={banner?.text} page={collection} parents={breadcrumbs} />
    </>
  )
)
