import React, { memo, useCallback, useMemo } from "react"

import { useSearchSwatches } from "../../../../hooks/useSearch"

export const withSearchFiltersSwatch = Component =>
  memo(({ name = "SearchFiltersSwatch", filterListProps, template }: any) => {
    const { getSwatch } = useSearchSwatches()

    const { data, handleChange, value } = filterListProps || {}

    const handleReset = useCallback(() => handleChange(value), [handleChange, value])
    const handleClick = useCallback((value: string) => handleChange(value), [handleChange])
    const capitalize = (word: string) => word.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())

    const all = useMemo(
      () => ({
        swatch: getSwatch(`all`),
        title: template?.additionalAll,
      }),
      [getSwatch, template]
    )

    const values = data?.map((item: any) => ({
      ...item,
      title: capitalize(item?.key),
      swatch: getSwatch(item?.key) || getSwatch(`all`),
    }))

    const items = useMemo(
      () => [
        ...(template?.swatches
          ?.filter(({ name }) => values?.find(({ title }) => title === name))
          ?.map(({ name }) => values?.find(({ title }) => title === name)) || []),
        ...(values?.filter(({ title }) => !template?.swatches?.find(({ name }) => name === title)) || []),
      ],
      [template, values]
    )

    Component.displayName = name
    return useMemo(
      () =>
        values?.length > 1 ? (
          <Component all={all} getSwatch={getSwatch} handleClick={handleClick} handleReset={handleReset} items={items} value={value} />
        ) : null,
      [all, getSwatch, handleClick, handleReset, items, value, values]
    )
  })
