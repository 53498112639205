import React from "react"
import { graphql } from "gatsby"

import { Collection as Template } from "../components/Collection/Collection"

export const query = graphql`
  query ($handle: String!) {
    page: sanityCollection(shopify: { shopifyDeleted: { ne: true }, shopifyHandle: { eq: $handle } }) {
      ...GatsbySanityCollectionFragment
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
    }
    template: sanityTemplateCollection {
      content: _rawContent(resolveReferences: { maxDepth: 6 })
      swatches {
        name
      }
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
      additionalAll
      additionalEmpty
      additionalLoadMoreButton
      additionalFaqs
      additionalFaqsShort
    }
  }
`

const Component = ({ data, ...props }): JSX.Element => {
  const page = {
    ...data?.page,
    title: data?.page?.title?.split(`#`)?.[0]?.trim(),
  }

  return <Template {...props} {...data} page={page} />
}

export default Component
