import React, { memo, useMemo } from "react"

import { useRoutes } from "../../../hooks/useRoutes"

export const withCollectionHeader = Component =>
  memo(({ name = "CollectionHeader", collection }: any) => {
    const { parentCollections } = useRoutes()
    const breadcrumbs = parentCollections(collection)
    const banner = useMemo(
      () => ({
        background: collection?.bannerBackground,
        content: collection?.descriptionHtml?.replace(/(<([^>]+)>)/gi, ""),
        position: `bottom`,
        size: `large`,
        title: collection?.title,
        text: collection?.bannerText?.toLowerCase(),
      }),
      [collection]
    )

    Component.displayName = name
    return useMemo(() => <Component banner={banner} breadcrumbs={breadcrumbs} collection={collection} />, [banner, breadcrumbs, collection])
  })
