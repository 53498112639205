import tw, { styled } from "twin.macro"

import { GlobalStyles } from "../../../Styled/Global"
import { StyledRow } from "../../../Styled/Row"
import { P } from "../../../Styled/Text"

export const Wrapper = tw.div`pt-6 md:pt-14`
export const Filters = tw(StyledRow)`overflow-y-hidden overflow-x-auto w-full justify-start`
export const Filter = styled.button`
  ${({ background }) => background && GlobalStyles.background[background]}
  ${({ colour }) => colour && GlobalStyles.colour[colour]}
  ${tw`relative flex flex-col justify-start items-center bg-opacity-0 focus:outline-none cursor-pointer md:min-w-27.5 py-3.5 px-3 md:py-3 md:px-7 md:flex-grow`}
  ${({ active }) => (active ? tw`bg-opacity-100` : tw`hover:bg-opacity-50`)}
  ${({ overflow }) => (overflow ? tw`w-1/4-screen md:w-auto` : tw`flex-1 md:flex-none`)}
`
export const Text = styled(P)`
  ${tw`text-xs md:text-base w-24 max-w-full`}
  ${({ active }) => active && tw`underline`}
`
