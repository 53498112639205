import React from "react"
import { Filter } from "@usereactify/search"

import { withSearchFilters } from "./withSearchFilters"
import { SearchFiltersSwatch } from "./Swatch/SearchFiltersSwatch"
import { Container } from "./styledSearchFilters"

export const SearchFilters = withSearchFilters(
  ({ filters, template }): JSX.Element =>
    filters?.map(filter => (
      <Container key={filter.id} width={`xl`}>
        <Filter filter={filter} renderFilterList={renderProps => <SearchFiltersSwatch {...renderProps} template={template} />} />
      </Container>
    ))
)
