import React, { memo, useEffect, useMemo } from "react"

import { useApp } from "../../hooks/useApp"
import { useShopify } from "../../hooks/useShopify"

export const withCollection = Component =>
  memo(({ name = "Collection", page, template }: any) => {
    const {
      activeCollection,
      setActiveCollection,
      searchKey,
      setSearchKey,
      config: { store },
    } = useApp()
    const { collectionNormaliser } = useShopify()

    const item = collectionNormaliser(page)
    const shopifyPermanentDomain = `${store.shopName}.myshopify.com`

    useEffect(() => {
      if (item?.id !== activeCollection?.id) setActiveCollection(item)
    }, [item, activeCollection, setActiveCollection])

    useEffect(() => {
      if (searchKey !== page?.shopify?.handle) setSearchKey(page?.shopify?.handle)
    }, [page, searchKey, setSearchKey])

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          collection={item}
          colour={item?.bannerBackground?.toLowerCase()?.replace(/ /g, `-`) || `purple-dark`}
          template={template}
          faqs={page?.faqs}
          faqsEnabled={page?.faqsEnabled}
          shopifyPermanentDomain={shopifyPermanentDomain}
        />
      ),
      [item, page?.faqsEnabled, page?.faqs, shopifyPermanentDomain, template]
    )
  })
