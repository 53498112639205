import React, { memo, useMemo } from "react"
import { useFilters } from "@usereactify/search"

export const withSearchFilters = Component =>
  memo(({ name = "SearchFilters", template }: any) => {
    const { filters } = useFilters()

    Component.displayName = name
    return useMemo(() => (filters?.length > 0 ? <Component filters={filters} template={template} /> : null), [filters, template])
  })
