import React from "react"
import { ReactifySearchProvider, Sensors } from "@usereactify/search"

import { withCollection } from "./withCollection"
import { CollectionHeader } from "./Header/CollectionHeader"
import { Sections } from "../Sections/Sections"
import { SearchFilters } from "../Search/Filters/SearchFilters"
import { SearchResults } from "../Search/Results/SearchResults"
import { CollectionContentAccordion } from "./Accordion/CollectionContentAccordion"

export const Collection = withCollection(
  ({ collection, template, colour, faqs, faqsEnabled, shopifyPermanentDomain }): JSX.Element => (
    <ReactifySearchProvider
      mode="collection"
      collectionHandle={collection?.shopify?.handle || ""}
      shopifyPermanentDomain={shopifyPermanentDomain ?? ""}
    >
      <Sensors />
      <CollectionHeader collection={collection} />
      <section>
        <SearchFilters template={template} />
        <SearchResults template={template} collection={collection} />
      </section>
      <Sections page={template} />
      {faqsEnabled ? <CollectionContentAccordion colour={colour} id={collection?.shopify?.handle} items={faqs} template={template} /> : null}
    </ReactifySearchProvider>
  )
)
