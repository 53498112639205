import React from "react"

import { withCollectionContentAccordion } from "./withCollectionContentAccordion"
import { Accordion } from "../../Sections/Accordion/Accordion"

export const CollectionContentAccordion = withCollectionContentAccordion(
  ({ items, locales, settings }): JSX.Element => (
    <Accordion {...settings} items={items} title={locales?.additionalFaqs} titleShort={locales?.additionalFaqsShort} />
  )
)
