import React from "react"

import { withSearchFiltersSwatch } from "./withSearchFiltersSwatch"
import { Icon } from "../../../Icon/Icon"
import { Filters, Filter, Text, Wrapper } from "./styledSearchFiltersSwatch"

export const SearchFiltersSwatch = withSearchFiltersSwatch(
  ({ all, handleClick, handleReset, items, value }): JSX.Element => (
    <Wrapper>
      <Filters items={`stretch`}>
        <Filter active={!value} background={`${all?.swatch?.colour}-pastel`} onClick={handleReset} overflow={items?.length > 2} title={all?.title}>
          <Icon colour={all?.swatch?.colour} name={`face-heart`} title={all?.title} width={54} />
          <Text active={!value} align={`center`}>
            {all?.title}
          </Text>
        </Filter>
        {items?.map(({ key, swatch, title }) => (
          <Filter
            key={key?.toString()}
            active={value === key}
            background={`${swatch?.colour}-pastel`}
            onClick={() => handleClick(key)}
            overflow={items?.length > 2}
            title={title}
          >
            <Icon colour={swatch?.colour} name={`face-${swatch?.icon}`} title={title} width={54} />
            <Text active={value === key} align={`center`}>
              {title}
            </Text>
          </Filter>
        ))}
      </Filters>
    </Wrapper>
  )
)
